import axios from "@/lib/axios";
import notification from "@/utils/notifications";
import shippingCarrierConstant from "@/constants/shipping-carrier-constant";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";

const fetchVendorInfo = async ({ commit }, vendorId) => {
  try {
    const response = await axios.get(`/accounts/v1/vendor/${vendorId}`);
    commit("setVendorInfo", response.data);
    return response && response.data;
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const updateVendorInfo = async ({ commit, getters }, payload) => {
  try {
    const vendorId = payload.id;
    const body = {
      name: payload.name,
      phone: payload.phone,
      fax: payload.fax,
      email: payload.email,
      website: payload.website,
      address1: payload.address1,
      address2: payload.address2,
      city: payload.city,
      region: payload.region,
      postalCode: payload.postalCode,
      country: payload.country,
      orderEmail: payload.orderEmail,
      orderCutoff: payload.orderCutoff,
      orderCutoffTz: payload.orderCutoffTz,
      handlingTime: payload.handlingTime,
      shippingCarrier: payload.shippingCarrier,
      description: payload.description,
      shippingTypeId: payload.shippingTypeId,
      shippingType: payload.shippingType,
    };
    let shippingCarrier = null;
    for (const prop in shippingCarrierConstant) {
      if (shippingCarrierConstant[prop].id === getters.getVendorInfo.shippingCarrierId) {
        shippingCarrier = shippingCarrierConstant[prop].value;
        break;
      }
    }
    const response = await axios.put(`/accounts/v1/vendor/${vendorId}`, body);
    if (response) {
      notification("success", "Successfully updated vendor info.");
      commit("setVendorInfo", response.data);
      if (shippingCarrier !== payload.shippingCarrier) {
        notification(
          "warning",
          "We partially added default shipping to all product that requires custom shipping service level, please ensure they properly align with new carrier."
        );
      }

      return response;
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const addShippingRateToInvoice = async (_, payload) => {
  try {
    const { locationId, purchaseId, shippingRates } = payload;
    await axios.post(`orders/v1/buyer/${locationId}/purchase/${purchaseId}/shipping`, shippingRates);
    notification("success", "Successfully added shipping rate");
  } catch (error) {
    return null;
  }
};


const inviteAsVendor = async ({ commit }, { body }) => {
  const proxy = new PostSimpatraServiceProxy();
  let query = `j-idp/v1/vendor/invite`;
  const response = await proxy.execute(query, body, {});
  if (response) {
    return response;
  }
};

export default {
  fetchVendorInfo,
  updateVendorInfo,
  addShippingRateToInvoice,
  inviteAsVendor,
};
